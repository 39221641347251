<template>
  <div>
    <v-app-bar
      clipped-left
      clipped-right
      app
      class="px-0 .d-flex"
      color="background"
    >
    <!-- Only show if the team is connected -->
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <router-link
        style="height: 40px"
        to="/dashboard"
        tag="img"
        :src="require('@/assets/rallylogga_svart.png')"
      />
      <v-spacer></v-spacer>
      <!-- <v-avatar :tile="true">
        <img :src="require('@/assets/arr_av_utn.png')">
      </v-avatar> -->
      <v-img
        class="mx-2"
        :src="require('@/assets/arr_av_utn.png')"
        max-height="110"
        max-width="110"
        contain
      ></v-img>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      clipped
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="red darken-4--text text--accent-4"
        >

          <v-list-item to="/dashboard" exact class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-monitor-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Hem</v-list-item-title>
          </v-list-item>

          <v-list-item to="/dashboard/stations" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-van-passenger</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Stationer</v-list-item-title>
          </v-list-item>

          <v-list-item to="/dashboard/missions" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-clipboard-check</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Uppdrag</v-list-item-title>
          </v-list-item>

          <v-list-item to="/dashboard/tricky" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-thought-bubble</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Kluring</v-list-item-title>
          </v-list-item> 

          <v-list-item to="/dashboard/haftig" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-flash</v-icon>
            </v-list-item-icon>
            <v-list-item-title>HÄFTiga platser</v-list-item-title>
          </v-list-item>
          
          <v-list-item to="/dashboard/splek" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-music-clef-treble</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Bilunderhållning</v-list-item-title>
          </v-list-item>

          <v-list-item to="/dashboard/lunch" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-food</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Lunchstation</v-list-item-title>
          </v-list-item>

          <v-divider/>

          <v-list-item to="/dashboard/contact" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-card-account-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Nö(r)dkontakter</v-list-item-title>
          </v-list-item>

          <v-list-item @click="signout" class="listItem">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logga ut</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  
    <router-view />
  </div>
</template>

<script>
export default {
    name: "Dashboard",
    data: () => ({
      drawer: false,
      group: null,
      // loading: true // TEST
    }),
    methods: {
      signout() {
        this.$store.dispatch('signOut')
        // Avoid "redundant navigation" error message
        this.$router.push({name: 'Home'}).catch(() => {})
      },
    }
}
</script>

<style>
.listItem{
  text-align: start;
}

</style>